<template>
  <div style="padding-top: 1em;">
    
    <h1 v-show="week != null && week.id != null">📅 Týden od {{ new Date(week.id).toLocaleDateString("cs-CZ") }}</h1>
    <h1 v-show="week == null || week.id == null">🧠 Načítám...</h1>

    <div class="day" 
         :class="{ today: new Date(day.date).toLocaleDateString() == new Date().toLocaleDateString()}" 
         v-for="day in week.days" v-bind:key="day.date.toString()">
      <h2>{{ dayNames[new Date(day.date).getDay()] }} ({{ new Date(day.date).toLocaleDateString("cs-CZ") }}) {{ day.shopping }}</h2>

      <table>
        <tr>
          <td>Oběd:</td>
          <td
            class="meal lunch"
            :class="{'has-data': day.lunch.id != null}"
            :id="day.date"
          >{{ day.lunch.title }}</td>
          <td>{{ day.lunch.comment }}</td>
        </tr>
        <tr>
          <td>Večeře:</td>
          <td
            class="meal dinner"
            :class="{'has-data': day.dinner.id != null}"
            :id="day.date"
          >{{ day.dinner.title }}</td>
          <td>{{ day.dinner.comment }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import * as Utils from "../utils";

export default {
  data: function() {
    return {
      dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
      week: {
        startsAt: new Date("AAAAA"),
        days: []
      }
    };
  },
  async created() {
    await this.defineRouteAndLoadData();
  },
  watch: {
    async $route(from, to) {
      console.log("route changed");
      await this.defineRouteAndLoadData();
    }
  },
  methods: {
    async defineRouteAndLoadData() {
      var startDateMs = Date.parse(this.$route.params.startsAt); // discouraged, but not as sensitive in this case, so using it
      if (isNaN(startDateMs)) {
        console.error("Provided starting date string is not a valid date.");
        // redirect to this week
        var monday = getWeekStart(new Date());
        this.$router.push({
          name: "headless",
          params: { startsAt: Utils.shortDateFormat(monday) }
        });
        return;
      }

      // is it Monday? if not, find nearest Monday (past)
      var startDate = new Date(startDateMs);
      if (startDate.getDay() != 1) {
        startDate = getWeekStart(startDate);
      }

      // query API for data - response can be empty if it's a week that hasn't been set
      var weekRes = await this.$MealPlannerService.getWeekAsync(startDate);

      if (weekRes === "EMPTY") {
        this.week = getEmtpyWeek(startDate);
      }
      // TODO: invalid date response
      else {
        this.week = weekRes;
      }
    },
    goToWeek(direction) { // direction is expected to be 1, 0 or -1
      if (!this.saved) {
         alert("Nejdřív si ulož změny, jinak o ně přijdeš!");
         return;
      }
      
      var thisWeek;
      if (direction != -1 && direction != 1) {
         thisWeek = getThisWeekStart();
      }
      else {
         thisWeek = new Date(this.week.startsAt);
         thisWeek.setDate(thisWeek.getUTCDate() + (7 * direction));
      }
      
      this.$router.push({
        name: "main",
        params: { startsAt: Utils.shortDateFormat(thisWeek) }
      });
    },
    logout() {
        this.$emit("logout");
    }
  }
};

// --------------------------

function getEmtpyWeek(startsAt) {
  var week = {
    id: Utils.shortDateFormat(startsAt),
    startsAt: new Date(startsAt),
    days: []
  };

  for (var i = 0; i < 7; i++) {
    var dayInWeek = new Date(startsAt);

    var weekDay = {
      date: new Date(dayInWeek.setDate(dayInWeek.getDate() + i)),
      lunch: getEmptyMeal(),
      dinner: getEmptyMeal()
    };
    week.days.push(weekDay);
  }

  return week;
}

function getEmptyMeal() {
  return {
    id: null,
    title: null,
    tags: []
  };
}

function getThisWeekStart() {
  var today = new Date();
  return getWeekStart(today);
}

function getWeekStart(date) {
  var weekStart = new Date(date);
  weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7));

  return weekStart;
}
</script>

<style scoped>
h1 {
   color: #1E704D;
   font-size: 16pt;
   padding-bottom: 8pt;
}

.day h2 {
   font-size: 14pt;
   font-weight: bold;
   margin-top: 0;
}

.day {
   padding: 10px;
   background-color: white;
   border-radius: 5px;
   margin-top: 5px;
}

.meal {
  padding: 6px;
  width: 100%
}

.today {
   background-color: #efefef;
}
</style>