<template>
  <div style="padding-top: 5em;">
    <div class="add-form">
      <b-button variant="outline-dark" v-on:click="showAddForm = !showAddForm">➕ Přidat jídlo...</b-button>
      <b-button style="margin: 4px" v-on:click="pickRandomMeal">Překvapení</b-button>
      <table v-show="showAddForm">
         <tr>
            <td>Název:</td>
            <td><input v-model="newMeal.title" /></td>
         </tr>
         <tr>
            <td>Tagy:</td>
            <td><input v-model="newMeal.tags" /></td>
            <td>(oddělené čárkou)</td>
         </tr>
         <tr>
            <td colspan="2" style="text-align: right">
               <b-button variant="success" v-on:click="addMeal">✅ OK</b-button>
            </td>
            <td>&nbsp;</td>
         </tr>
      </table>
    </div>

    <div class="add-form">
       <input style="width: 100%" placeholder="Hledat..." v-model="filter" />
    </div>

   <BacklogItem 
      v-for="meal in filteredMeals" :key="meal.id" 
      :meal="meal" 
      @save-meal="saveMeal($event)"
      @delete-meal="deleteMeal($event)"
   />

  </div>
</template>

<script>
import BacklogItem from "../components/BacklogItem";

export default {
  data: function() {
    return {
      meals: [],
      newMeal: {
        title: "",
        tags: ""
      },
      showAddForm: false,
      filter: ""
    };
  },
  computed: {
      filteredMeals() {
         var self = this;
         return this.meals.filter(item => {
             var filterLowerCase = self.filter.toLowerCase();
             
             if (item.title.toLowerCase().indexOf(self.filter.toLowerCase()) > -1)
                return true;

             for (var i = 0; i < item.tags.length; i++) {
                 if (item.tags[i].toLowerCase().indexOf(filterLowerCase) > -1)
                    return true;
             }
             
             return false;
         });
      }
  },
  async created() {
    this.refreshMeals();
  },
  methods: {
    async refreshMeals() {
      var meals = await this.$MealPlannerService.getMealsAsync();
      this.meals = meals;
      this.meals.sort(function (a, b) {
         return a.title.localeCompare(b.title, "cs", { sensitivity: "base" });
      });
    },
    async addMeal() {
      var tags = [];
      if (this.newMeal.tags.trim() != "") {
        tags = this.newMeal.tags.split(",");
        tags.forEach((el, idx) => (tags[idx] = el.trim()));
      }

      var mealToAdd = {
        title: this.newMeal.title,
        tags: tags
      };

      await this.$MealPlannerService.addMealAsync(mealToAdd);
      await this.refreshMeals();
      this.newMeal = {
         title: "",
         tags: ""
      }
    },
    async saveMeal(meal) {
      await this.$MealPlannerService.saveMealAsync(meal);
      await this.refreshMeals();
    },
    async deleteMeal(meal) {
      await this.$MealPlannerService.deleteMealAsync(meal);
      await this.refreshMeals();
    },
    pickRandomMeal() {
       var randIndex = Math.floor(Math.random() * (this.meals.length));
       this.filter = this.meals[randIndex].title;
    }
  },
  components: {
     BacklogItem
  }
};
</script>

<style scoped>
.add-form {
  padding: 6px;
  margin: 4px;
  background-color: rgb(179, 219, 202);
  border-radius: 3px;
}

</style>