<template>
  <div
    id="meal-item"
    v-bind:draggable="!isEditing"
    @dragstart="dragStart"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
    v-bind:style="!isEditing ? 'cursor: move' : ''"
  >
    <span v-if="!isEditing">{{ currentMeal.title }}</span>

    <span v-if="isEditing">
      <input v-model="currentMeal.title" />
      <span @click="saveMeal()" title="Uložit" style="cursor: pointer">✅</span>
      <span @click="cancelEdit()" title="Zrušit" style="cursor: pointer">❌</span>
    </span>

    <span
      v-show="isHover && !isEditing"
      @click="beginEdit()"
      title="Upravit"
      style="cursor: pointer"
    >✏️</span>

    <span
      v-show="isHover && !isEditing"
      @click="deleteMeal()"
      title="Smazat"
      style="cursor: pointer"
    >🗑️</span>

    <div>
      <span v-if="!isEditing" class="tag" v-for="tag in meal.tags">{{ tag }}</span>
      <input v-if="isEditing" ref="tagsInput" v-bind:value="tagsToEdit" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meal: Object
  },
  data: function() {
    return {
      isEditing: false,
      isHover: false,
      currentMeal: null,
      originalMeal: null
    }
  },
  computed: {
    tagsToEdit() {
       return this.currentMeal.tags.join(", ");
    }
  },
  created() {
    this.currentMeal = JSON.parse(JSON.stringify(this.meal));
  },
  methods: {
    dragStart(ev) {
      ev.dataTransfer.setData("meal", JSON.stringify(this.meal));
    },
    beginEdit() {
      this.originalMeal = JSON.stringify(this.currentMeal);
      this.isEditing = true;
    },
    saveMeal() {
      this.isEditing = false;
      var tags = [];
      if (this.$refs.tagsInput.value.trim() != "") {
        tags = this.$refs.tagsInput.value.split(",");
        tags.forEach((el, idx) => (tags[idx] = el.trim()));
      }
      this.currentMeal.tags = tags;
      this.$emit("save-meal", this.currentMeal);
    },
    cancelEdit() {
      this.currentMeal = JSON.parse(this.originalMeal);
      this.isEditing = false;
    },
    deleteMeal() {
      this.$emit("delete-meal", this.currentMeal);
    }
  }
}
</script>

<style scoped>
#meal-item {
  padding: 10px;
  margin: 5px;
  background-color: rgb(179, 219, 202);
  border-radius: 3px;
}
#meal-item:hover {
  background: rgb(147, 212, 187);
}

.tag {
  background-color: greenyellow;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 4px;
  font-size: 10pt;
}
</style>