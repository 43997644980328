<template>
  <div style="padding-top: 5em;">
    <b-button-toolbar key-nav class="fixed-top navigation shadow">
        <b-button-group class="mx-1" size="sm">
            <b-button variant="primary" v-on:click="saveWeek">💾 Uložit</b-button>
            <b-button variant="outline-dark" v-on:click="discardChanges">↩️ Zrušit změny</b-button>
        </b-button-group>
        <b-button-group class="mx-3" size="sm">
            <b-button title="Předchozí týden" variant="outline-dark" v-on:click="goToWeek(-1)">⬅️</b-button>
            <b-button title="Tento týden" variant="outline-dark" v-on:click="goToWeek(0)">⬇️</b-button>
            <b-button title="Příští týden" variant="outline-dark" v-on:click="goToWeek(1)">➡️</b-button>
        </b-button-group>
        <b-button-group class="mx-1" size="sm">
            <b-button title="Odhlásit" variant="outline-danger" v-on:click="logout">Odhlásit</b-button>
        </b-button-group>
    </b-button-toolbar>
    
    <h1 v-show="week != null && week.id != null">📅 Týden od {{ new Date(week.id).toLocaleDateString("cs-CZ") }}</h1>
    <h1 v-show="week == null || week.id == null">🧠 Načítám...</h1>

    <div class="day" 
         :class="{ today: new Date(day.date).toLocaleDateString() == new Date().toLocaleDateString()}" 
         v-for="day in week.days" v-bind:key="day.date.toString()">
      <h2>{{ dayNames[new Date(day.date).getDay()] }} ({{ new Date(day.date).toLocaleDateString("cs-CZ") }}) {{ day.shopping }}</h2>

      <table>
        <tr>
          <td>Oběd:</td>
          <td
            class="meal lunch"
            :class="{'has-data': day.lunch.id != null}"
            :id="day.date"
            v-on:drop="dropMeal"
            v-on:dragover="allowDrag"
            v-on:dragleave="clearDrag"
          >{{ day.lunch.title }}</td>
          <td>{{ day.lunch.comment }}</td>
          <td>
            <span
              v-on:click="removeMeal(day.date, 'lunch')"
              style="cursor: pointer"
              title="Remove"
            >🗑️</span>
          </td>
        </tr>
        <tr>
          <td>Večeře:</td>
          <td
            class="meal dinner"
            :class="{'has-data': day.dinner.id != null}"
            :id="day.date"
            v-on:drop="dropMeal"
            v-on:dragover="allowDrag"
            v-on:dragleave="clearDrag"
          >{{ day.dinner.title }}</td>
          <td>{{ day.dinner.comment }}</td>
          <td>
            <span v-on:click="removeMeal(day.date, 'dinner')" style="cursor: pointer" title="Remove">🗑️</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import * as Utils from "../utils";

export default {
  data: function() {
    return {
      dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
      week: {
        startsAt: new Date("AAAAA"),
        days: []
      },
      saved: true // if anything changes in current week, this is used to block page leave without saving
    };
  },
  async created() {
    await this.defineRouteAndLoadData();
    window.addEventListener("beforeunload", (event) => {
       console.log("trying to leave");
       if (!this.saved) {
          event.returnValue = "";
       }
    });
  },
  watch: {
    async $route(from, to) {
      console.log("route changed");
      await this.defineRouteAndLoadData();
    }
  },
  methods: {
    async defineRouteAndLoadData() {
      var startDateMs = Date.parse(this.$route.params.startsAt); // discouraged, but not as sensitive in this case, so using it
      if (isNaN(startDateMs)) {
        console.error("Provided starting date string is not a valid date.");
        // redirect to this week
        var monday = getWeekStart(new Date());
        this.$router.push({
          name: "main",
          params: { startsAt: Utils.shortDateFormat(monday) }
        });
        return;
      }

      // is it Monday? if not, find nearest Monday (past)
      var startDate = new Date(startDateMs);
      if (startDate.getDay() != 1) {
        startDate = getWeekStart(startDate);
      }

      // query API for data - response can be empty if it's a week that hasn't been set
      var weekRes = await this.$MealPlannerService.getWeekAsync(startDate);

      if (weekRes === "EMPTY") {
        this.week = getEmtpyWeek(startDate);
      }
      // TODO: invalid date response
      else {
        this.week = weekRes;
      }
    },
    async saveWeek() {
      await this.$MealPlannerService.saveWeekAsync(this.week);
      this.saved = true;
    },
    async discardChanges() {
      const startDate = new Date(this.week.startsAt);
      var weekRes = await this.$MealPlannerService.getWeekAsync(startDate);
      if (weekRes === "EMPTY") {
        this.week = getEmtpyWeek(startDate);
      }
      // TODO: invalid date response
      else {
        this.week = weekRes;
      }
      this.saved = true;
    },
    goToWeek(direction) { // direction is expected to be 1, 0 or -1
      if (!this.saved) {
         alert("Nejdřív si ulož změny, jinak o ně přijdeš!");
         return;
      }
      
      var thisWeek;
      if (direction != -1 && direction != 1) {
         thisWeek = getThisWeekStart();
      }
      else {
         thisWeek = new Date(this.week.startsAt);
         thisWeek.setDate(thisWeek.getUTCDate() + (7 * direction));
      }
      
      this.$router.push({
        name: "main",
        params: { startsAt: Utils.shortDateFormat(thisWeek) }
      });
    },
    logout() {
        this.$emit("logout");
    },
    dropMeal(ev) {
      ev.preventDefault();
      var meal = JSON.parse(ev.dataTransfer.getData("meal"));
      var classList = ev.target.classList;
      var id = ev.target.id;

      this.week.days.forEach((val, idx) => {
        if (val.date == id) {
          if (classList.contains("lunch")) {
            val.lunch = meal;
          }

          if (classList.contains("dinner")) {
            val.dinner = meal;
          }
        }
      });
      this.clearDrag(ev);
      this.saved = false;
    },
    allowDrag(ev) {
      ev.preventDefault();
      ev.currentTarget.classList.add("dragged-over");
      ev.currentTarget.classList.remove("not-dragged-over");
    },
    clearDrag(ev) {
      ev.currentTarget.classList.add("not-dragged-over");
      ev.currentTarget.classList.remove("dragged-over");
    },
    removeMeal(date, type) {
      console.log("removing", date, type);
      this.week.days.forEach((val, idx) => {
        if (val.date == date) {
          type == "lunch"
            ? (val.lunch = getEmptyMeal())
            : (val.dinner = getEmptyMeal());
        }
      });
      this.saved = false;
    }
  }
};

// --------------------------

function getEmtpyWeek(startsAt) {
  var week = {
    id: Utils.shortDateFormat(startsAt),
    startsAt: new Date(startsAt),
    days: []
  };

  for (var i = 0; i < 7; i++) {
    var dayInWeek = new Date(startsAt);

    var weekDay = {
      date: new Date(dayInWeek.setDate(dayInWeek.getDate() + i)),
      lunch: getEmptyMeal(),
      dinner: getEmptyMeal()
    };
    week.days.push(weekDay);
  }

  return week;
}

function getEmptyMeal() {
  return {
    id: null,
    title: null,
    tags: []
  };
}

function getThisWeekStart() {
  var today = new Date();
  return getWeekStart(today);
}

function getWeekStart(date) {
  var weekStart = new Date(date);
  weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() + 6) % 7));

  return weekStart;
}
</script>

<style scoped>
h1 {
   color: #1E704D;
   font-size: 16pt;
   padding-bottom: 8pt;
}

.navigation {
   border-bottom: 2px solid;
   padding: 8px;
   background: #d5f1e5;
}

.day h2 {
   font-size: 14pt;
   font-weight: bold;
   margin-top: 0;
}

.day {
   padding: 10px;
   background-color: white;
   border-radius: 5px;
   margin-top: 5px;
}

.meal {
  border: 1px dashed #ccc;
  padding: 6px;
  width: 100%
}
.empty {
  border-style: dashed;
}
.has-data {
  border-style: solid;
  border: none;
}

.dragged-over {
  background-color: #aaa;
}

.not-dragged-over {
  background-color: transparent;
}

.today {
   background-color: #efefef;
}
</style>