<template>
  <div id="app">
    <div id="loginDialog" v-if="!$AuthService.loggedIn">
      <p>Použití tohoto webu je omezeno pro vybrané uživatele. Přihlašte se svým heslem, prosím.</p>
      <input type="password" v-model="pass" />
      <button class="main" @click="login">Přihlásit</button>

      <p>{{ message }}</p>
    </div>
    
    <b-container v-if="$AuthService.loggedIn">
        <b-row>
            <b-col class="week">
                <WeekView @logout="logout" class="sticky-top" />
            </b-col>
            <b-col class="backlog">
                <BacklogView />
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
import WeekView from "./WeekView.vue";
import BacklogView from "./BacklogView.vue";

export default {
  data: function() {
   return {
      pass: "",
      message: ""
   };
  },
  async created() {
   this.message = "Kontrola předchozího přihlášení...";
   var res = await this.$AuthService.checkTokenAsync();
   if (!res) {
      this.message = "Je potřeba zadat heslo.";
   }
   else {
      this.message = "Příhlášení v pořádku.";
   }
  },
  methods: {
    async login() {
      this.message = "Přihlašování...";
      await this.$AuthService.login(this.pass);
    },
    logout() {
      this.$AuthService.logout();
      // TODO: refresh browser?
    }
  },
  computed: {},
  components: {
    WeekView,
    BacklogView
  }
};
</script>

<style>
body {
  margin: 0px;
  background-color: #d5f1e5;
}

#app {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}

#loginDialog {
   margin: 20px;
}

h1,
h2 {
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  margin-top: 0;
}

button.main {
  font-weight: bold;
  background-color: #0078d7;
  border-color: #0078d7;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
button.main:hover {
  background-color: rgb(47, 72, 184);
}

header {
  background-color: blue;
}

.panel {
  background-color: #efefef;
  padding: 10px;
  border: 1px dotted;
  display: inline;
}
</style>