import * as Utils from "../utils"
import config from '../config'

const API_ENDPOINT = config.webApi;

export default class MealPlannerService {
   constructor(authService) {
      this.authService = authService;
   }

   async getWeekAsync(startingAt) {
      const accessToken = (await this.authService.getTokenAsync());

      // 200 -> validní datum a máme data
      // 404 -> validní datum, ale nemáme data -> vyplnit naprázdno
      // 500 -> invalid date
      NProgress.start();
      var date = Utils.shortDateFormat(startingAt);
      var res = await fetch(`${API_ENDPOINT}/week/${date}`, {
         headers: {
            "X-Access-Token": `${accessToken}`
         }
      });
      NProgress.done();

      if (res.ok) {
         return res.json();
      }
      else {
         switch (res.status) {
            case 404:
               return "EMPTY";
            case 500:
               return "INVALID_DATE";
            default:
               return "UNKNOWN_ERROR";
         }
      }
   }

   async saveWeekAsync(week) {
      const accessToken = (await this.authService.getTokenAsync());

      // so far always returns 200
      NProgress.start();
      await fetch(`${API_ENDPOINT}/week/save`, {
         method: "POST",
         headers: {
            "X-Access-Token": `${accessToken}`,
            "Content-Type": "application/json"
         },
         body: JSON.stringify(week)
      });
      NProgress.done();
   }

   async getMealsAsync() {
      const accessToken = (await this.authService.getTokenAsync());

      NProgress.start();
      var res = await fetch(`${API_ENDPOINT}/meals`, {
         headers: {
            "X-Access-Token": `${accessToken}`
         }
      });
      NProgress.done();
      
      if (res.ok) {
         return res.json();
      }
      else {
         //TODO: error handling
      }
   }

   //TODO: refactor the following - change only HTTP methods
   async addMealAsync(meal) {
      await this.mealOperationAsync(meal, "add");
   }

   async saveMealAsync(meal) {
      await this.mealOperationAsync(meal, "save");
   }

   async deleteMealAsync(meal) {
      await this.mealOperationAsync(meal, "delete");
   }

   async mealOperationAsync(meal, operation) {
      var method;
      switch (operation.toLowerCase()) {
         case "add":
            method = "POST";
            break;
         case "save":
            method = "PATCH";
            break;
         case "delete":
            method = "DELETE";
            break;
         default:
            throw new Error("Unknown operation. Use 'add', 'save', or 'delete'.");
      }
      
      const accessToken = (await this.authService.getTokenAsync());

      NProgress.start();
      var res = await fetch(`${API_ENDPOINT}/meal`, {
         method: method,
         headers: {
            "X-Access-Token": `${accessToken}`,
            "Content-Type": "application/json"
         },
         body: JSON.stringify(meal)
      });

      //TODO: error handling

      NProgress.done();
   }
}

// get week
export async function getWeekAsync(startingAt) {
   // 200 -> validní datum a máme data
   // 404 -> validní datum, ale nemáme data -> vyplnit naprázdno
   // 500 -> invalid date
   NProgress.start();
   var date = Utils.shortDateFormat(startingAt);
   var res = await fetch(`${API_ENDPOINT}/week/${date}`);
   NProgress.done();

   switch (res.status) {
      case 404:
         return "EMPTY";
      case 500:
         return "INVALID_DATE";
      case 200:
         return res.json();
      default:
         return "UNKNOWN_ERROR";
   }
}

// // save week
// export async function saveWeekAsync(week) {
//    // so far always returns 200
//    NProgress.start();
//    var res = await request.post(API_ENDPOINT + "/week/save", { body: week, json: true });
//    NProgress.done();
// }

// // load backlog - get meals
// export async function getMeals() {
//    NProgress.start();
//    var res = await fetch(`${API_ENDPOINT}/meals`);
//    NProgress.done();
//    return res.json();
// }

// //TODO: refactor the following - change only HTTP methods
// export async function addMeal(meal) {
//    NProgress.start();
//    var res = await request.post(API_ENDPOINT + "/meal", { body: meal, json: true });
//    NProgress.done();
// }

// export async function saveMeal(meal) {
//    NProgress.start();
//    var res = await request.patch(API_ENDPOINT + "/meal", { body: meal, json: true });
//    NProgress.done();
// }

// export async function deleteMeal(meal) {
//    NProgress.start();
//    var res = await request.delete(API_ENDPOINT + "/meal", { body: meal, json: true });
//    NProgress.done();
// }