import config from '../config'

const TOKEN_KEY = "token";

export default class AuthService {
   

   constructor() {
      this.loggedIn = false;
   }

   async checkTokenAsync() {
      var token = await this.getTokenAsync();

      var res = await fetch(`${config.webApi}/auth/token/validate`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json"
         },
         body: JSON.stringify({token: token})
      });

      this.loggedIn = res.ok;
      return res.ok;
   }
   
   async getTokenAsync() {
      // try to get token from local cache
      return localStorage.getItem(TOKEN_KEY);
   }

   async login(password) {
      var res = await fetch(`${config.webApi}/auth/token`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json"
         },
         body: JSON.stringify({password: password})
      });

      if (res.ok) {
         var token = await res.text();
         localStorage.setItem(TOKEN_KEY, token)
         this.loggedIn = true;
         return true;
      }
      else {
         console.log("Auth error.", res.status);
         this.loggedIn = false;
         return false;

      }
   }

   logout() {
      localStorage.removeItem(TOKEN_KEY);
   }
}