import { createApp, reactive } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import App from './App.vue'
import Main from './components/Main.vue'
import Headless from './components/HeadlessView.vue'
import AuthService from './services/auth-service'
import MealPlannerService from './services/mealplanner-service'

const appInsights = new ApplicationInsights(
{ 
   config: {
      instrumentationKey: 'e90e9a34-73a4-4406-b65b-40174ccea1d8',
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true
   } 
});

appInsights.loadAppInsights();
appInsights.trackPageView();

const app = createApp(App);

// Hack to make global $AuthService observable and be able to react to login changes.
// From: https://stackoverflow.com/a/58968089/11604684, updated for Vue3 using reactive.
const authService = reactive({ authService: {} });

Object.defineProperty(app.config.globalProperties, '$AuthService', {
  get () {
    return authService.authService;
  },
  
  set (value) {
    authService.authService = value;
  }
})

app.config.globalProperties.$AuthService = new AuthService()
app.config.globalProperties.$MealPlannerService = new MealPlannerService(app.config.globalProperties.$AuthService)

const router = createRouter({
   history: createWebHashHistory(),
   routes: [
      {
        path: "/",
        name: "mainNoDate",
        component: Main
      },
      {
         path: "/headless",
         name: "headless",
         component: Headless
      },
      {
        path: "/:startsAt",
        name: "main",
        component: Main
      }
    ]
});

app.use(router).use(BootstrapVue3).mount("#app");
